@use "./config" as *;

.home-standing {
  .waf-head {
    .title {
      text-transform: uppercase;
    }
  }
  .table {
    @include flex-config(flex, null, null, null);

    &-data {
      border-bottom: 0.1rem solid rgba(var(--light-grey-color-rgb), 0.1);

      p,
      .text {
        color: var(--white-color);
      }
    }

    &-head {
      height: 3.4rem;
      // background-color: var(--kabaddi-accent-color);
      background-color: var(--kabaddi-primary-color);

      .table-data {
        p,
        .text {
          color: var(--white-color);
        }
      }
    }

    &-body {
      background-color: var(--kabaddi-header-bg);

    }

    &-left {
      .table-row {
        // padding-left: var(--half-space);

        &::after {
          @include position(null, 0, 0, null);
        }
      }
    }

    &-right {
      .table-row {
        // padding-right: var(--half-space);

        &::after {
          @include position(null, null, 0, 0);
        }
      }

      .table-data {
        width: calc(100% / 5);
      }
    }

    &-row {
      position: relative;

      &::after {
        content: "";
        width: calc(100% - var(--full-space));
        height: 0.1rem;
        background-color: rgba(var(--light-grey-rgb), 0.15);
      }

      &.highlight {
        .table-data {
          background-color: rgba(var(--kabaddi-primary-color-rgb), 0.5);
        }
      }
    }
  }
}
.wpl-page{
  .waf-standings{
    .table {
      &-data {
        &.net-rr{
          @extend %flex-n-c;
        }
      }
    }
  }
  .home-standing{
    background: var(--white-color);
    .table{
      &-row{
        &.highlight{
          .table-data{
            background-color: rgba(var(--kabaddi-primary-color-rgb), 0.1);
            &.position{
              position: relative;
              &::after{
                content: "";
                width: 0.5rem;
                background-color: rgba(var(--kabaddi-primary-color-rgb), 0.5);
                @include position-style(null, 0, null, null, 0);
                @extend %h-100;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .home-standing {
    position: fixed;
    top: calc(var(--main-header-height) + 4rem);
    background-color: var(--fourth-color);
    width: var(--standing-width);
    flex-basis: var(--standing-width);
    flex-shrink: 0;
    z-index: var(--z-index10);
    right: calc(-1 * (var(--standing-width) - var(--half-space)));
    @include border-radius(var(--half-radius) 0 0 var(--half-radius), visible);
    @include transition(500ms right ease-in-out);
    &.active {
      right: var(--half-space);

      .accordion-btn .btn-text::after {
        @include transform(rotate(270deg));
      }
    }

    .waf-head {
      .title {
        color: var(--white-color);
        font-size: 1.6rem;
        text-transform: capitalize;
      }

      .head-tab li a {
        border-color: var(--light-grey-color);
        color: var(--light-grey-color);
      }
    }

    .table-body {
      max-height: 32.5rem;
      overflow-y: auto;
    }

    .waf-footer {
      display: block;
      left: -2.8rem;
      @include center(vertical);
    }

    .accordion-btn {
      width: 2.8rem;
      height: 14.45rem;
      background-color: var(--kabaddi-primary-color);
      @include linear-gradient(270deg, var(--kabaddi-accent-color) 0%, var(--kabaddi-primary-color) 44%);
      @include border-radius(var(--half-radius) 0 0 var(--half-radius));
      @include flex-config(flex, null, center, center);

      .btn-text {
        color: var(--black-color);
        font: 1.3rem/1.4 $font-bold;
        text-transform: capitalize;
        display: block;
        width: 100%;
        writing-mode: vertical-lr;
        @include flex-config(flex, row-reverse, flex-end, center);

        &::after {
          content: "";
          color: var(--kabaddi-accent-color);
          font: 1.3rem/1.2 $font-icon;
          background: url(/static-assets/images/cssimages/shape-icon.png?v=#{$image-version}) no-repeat;
          background-size: 100% 100%;
          width: 5.8rem;
          height: 2.8rem;
          position: relative;
          top: -10px;
          @include transform(rotate(90deg));
          @include transition(500ms ease-in);
        }
      }
    }

    // .table-right {
    //     overflow-x: unset;
    // }
    .waf-select-box {
      .selected-title {
        border-color: var(--white-color);

        &::after {
          color: var(--white-color);
        }
      }

      .btn-text {
        color: var(--white-color);
      }
    }

    .standings-table {
      max-height: 32.5rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 0.5rem;
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: rgba(var(--light-grey-color-rgb), 0.6);
        border-radius: 0.5rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: var(--light-grey-color);
      }

      &::-webkit-scrollbar-thumb:hover {
        border-radius: 1rem;
        background: var(--kabaddi-primary-color);
      }
    }

    .table-responsive {
      position: relative;
      padding-top: 3.4rem;
    }

    .table {
      &-left,
      &-right {
        .table-body {
          overflow: unset;
          max-height: unset;
        }

        .table-head {
          z-index: var(--standing-head-z);
        }
      }

      &-left {
        .table-head {
          width: 44%;
          @include position(0, null, null, 0);
        }
      }

      &-right {
        overflow-x: unset;

        .table-head {
          width: 56%;
          @include position(0, 0, null, null);

          .table-row {
            padding-right: calc(var(--half-space) + 0.5rem);
          }
        }
      }
    }
  }
  .kabaddi-page {
    .home-standing {
      background-color: var(--kabaddi-header-bg);
    }
  }
  .wpl-page{
    .home-standing{
      background: var(--tertiary-color);
      .table-right{
        .table-head{
          .table-row{
            padding-right: 0;
          }
        }
      } 
    }
  }
}
