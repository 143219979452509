@use "./config" as *;

// .home {
//     .main-wrap {
//         padding-top: 0;
//     }
// }

.about-teams-wrapper {
    background: url(/static-assets/images/cssimages/home-team-bg-mob.png?v=#{$image-version}) no-repeat center/cover;
    margin: var(--half-space-negative);

}

.waf-about {
    padding: 0;
    margin: 0 var(--full-space);
    @include translate(0, -15%);

    &::after {
        content: "";
        background-color: var(--secondary-color);
        width: 80%;
        height: 0.5rem;
        bottom: 0;
        @include center(horizontal);
    }

    .layout-wrapper {
        background: url("/static-assets/images/cssimages/about-bg-mweb.png?v=#{$image-version}") no-repeat;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        padding: var(--full-space);
    }


    .waf-head {
        margin: var(--full-space) 0;

        .title {
            font: 2.4rem/3.6rem $font-bold;
            color: var(--text-color);
            text-transform: uppercase;
        }
    }

    .content {
        .text {
            font: 1.4rem/2.5rem $font-medium;
            margin-bottom: var(--full-space);
        }
    }
}

.waf-anthem {
    background: url(/static-assets/images/cssimages/anthem-video-bg-mob.png?v=#{$image-version}) no-repeat center/cover;
    text-align: center;
    // background: var(--primary-color);
    margin: var(--half-space) var(--half-space-negative);

    .waf-head {
        margin: var(--full-space) 0;

        .title {
            font: 2.4rem/3rem $font-bold;
            color: var(--white-color);
            text-transform: uppercase;
        }
    }

    .content {
        .text {
            font: 1.4rem/1.8rem $font-regular;
            color: var(--white-color);
        }
    }

    .video-wrapper {
        margin-top: var(--full-space);
        width: 100%;

        iframe {
            width: 100%;
        }
    }
}

.waf-team {
    margin: calc(5 * var(--full-space-negative)) var(--half-space-negative) var(--full-space);

    .head-wrap {
        @include flex-config(null, null, center, null);
    }

    .title {
        font: 2.4rem/3.6rem $font-bold;
        color: var(--text-color);
    }

    .card {
        &-list {
            overflow: auto;
            margin-bottom: calc(2 * var(--full-space));
            @include flex-config(flex, row, null, null);
        }

        &-item {
            flex-shrink: 0;
            box-shadow: 0 0.4rem 0.4rem rgba(var(--black-color-rgb), 0.25);
            margin-bottom: var(--full-space);
            @include border-radius(0 0 0.5rem 0.5rem);
            @include card-count(1.2, var(--full-space));

            &.pro-kabaddi {
                .card-head {
                    @include linear-gradient(180deg, var(--pro-kabaddi-gradient) 0%, var(--text-color) 72.95%);
                }
            }

            &.legend-cricket {
                .card-head {
                    @include linear-gradient(180deg, var(--legend-cricket-dark-gradient) 0%, var(--legend-cricket-light-gradient) 72.95%);
                }
            }

            &.ukk {
                .card-head {
                    @include linear-gradient(180deg, var(--ukk-dark-gradient) 0%, var(--ukk-light-gradient) 72.95%);
                }
            }

            &.polo {
                .card-head {
                    @include linear-gradient(180deg, var(--polo-light-gradient) 0%, var(--polo-dark-gradient) 72.95%);
                }
            }

            &.boxing {
                .card-head {
                    @include linear-gradient(180deg, var(--pro-kabaddi-gradient) 0%, var(--text-color) 72.95%);
                }
            }
        }

        &-head {
            height: 13.8rem;
            background-color: var(--primary-color);
            padding: var(--full-space);
            @include flex-config(flex, row, null, center);
            @include border-radius(0.5rem 0.5rem 0 0);
        }

        &-image {
            width: 8.8rem;
            height: 8.8rem;
            border: 0.2rem solid var(--accent-color);
            @include border-radius(50%);

            .img-box {
                background-color: var(--white-color);
            }

            img {
                height: 100%;
                padding: var(--half-space);
            }
        }

        &-title {
            color: var(--white-color);
            font: 2rem/2.4rem $font-bold;
            text-transform: uppercase;
            width: calc(100% - 8.8rem);
            padding-left: var(--full-space);
        }

        &-body {
            background-color: var(--white-color);
            text-align: center;
            padding: var(--full-space);

            .title {
                font: 1.8rem/2.5rem $font-bold;
                color: var(--secondary-color);
            }
        }

        &-link {
            margin: calc(2 * var(--full-space)) 0 calc(2 * var(--full-space));
            @include flex-config(flex, null, center, center);
        }
    }

    .description {
        font: 1.4rem/2.5rem $font-medium;
        color: var(--text-color);
    }

    .goto-site {
        position: relative;
        width: 16rem;
        height: 3.9rem;
        padding-right: var(--full-space);
        @include flex-config(flex, null, center, center);
        @include border-radius(0.5rem);
        @include linear-gradient(180deg, var(--ukk-dark-gradient) 33.75%, var(--ukk-light-gradient) 160.18%);

        &::after {
            content: '\e85e';
            font: 1.2rem $font-icon;
            @include position(37%, 26%, null, null);
        }
    }

}

.home {
    .home-video {
        background: var(--white-color);

        .item-type-video {
            .article-thumbnail {
                @include thumb-clip(92%, 94%);
            }
        }

        .item-type-icon {
            clip-path: polygon(85% 0, 100% 14%, 100% 100%, 0 100%, 0 0);
        }

        .head-wrap {
            @include flex-config(null, null, center, null);
        }

        .title {
            color: var(--secondary-color);
        }

        .article {
            &-item {
                .item-type-icon {
                    width: 3.4rem;
                    height: 3.4rem;
                    @include linear-gradient(180deg, var(--polo-light-gradient) 8.29%, var(--ukk-dark-gradient) 100%);

                    &::after {
                        color: var(--secondary-color);
                    }
                }
            }
        }

        .article-title {
            color: var(--secondary-color);
        }

        .second-list {
            padding-top: var(--full-space);
            border-top: 0.1rem solid rgba(var(--fourth-color-rgb), 0.2);
        }
    }
}

@media (min-width: $tablet-min-width) {
    .home {
        .home-video {
            .second-list {
                border-top: none;

                .article-thumbnail {
                    @include thumb-clip(94%, 91%);
                }
            }
        }
    }

    .waf-banner {
        margin: 0 var(--half-space-negative);
    }

    .waf-about {
        position: relative;
        @include translate(0, -40%);

        &::after {
            width: 50%;
        }

        .layout-wrapper {
            background-image: url("/static-assets/images/cssimages/about-bg-web.png?v=#{$image-version}");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-color: unset;
            max-width: calc(var(--container-max-width) - calc((var(--window-inner-width) - var(--container-max-width))/2));
            padding: calc(2 * var(--full-space)) calc(6 * var(--full-space)) calc(2 * var(--full-space)) calc(3 * var(--full-space));
            margin: auto;
            position: relative;

            &::before {
                content: "";
                background: url(/static-assets/images/cssimages/home-about-avatar.png?v=#{$image-version}) no-repeat center/contain;
                width: 18rem;
                height: 25rem;
                @include position(null, -9%, -10%, null);
            }
        }
    }

    .waf-anthem {
        background: url(/static-assets/images/cssimages/anthem-video-bg-web.png?v=#{$image-version}) no-repeat center/cover;

        .layout-wrapper {
            max-width: calc(var(--container-max-width) - calc((var(--window-inner-width) - var(--container-max-width))/2));
            margin: calc(3 * var(--full-space)) auto;
        }

        .waf-head {
            .title {
                font: 4rem/5rem $font-bold;
            }
        }

        .content {
            .text {
                font: 1.8rem/2.4rem $font-medium;
            }
        }

        .video-wrapper {
            height: 50rem;

            iframe {
                height: 100%;
            }
        }
    }

    .about-teams-wrapper {
        background: url(/static-assets/images/cssimages/home-team-bg-web.png?v=#{$image-version}) no-repeat;
        background-size: cover;
        margin: var(--half-space-negative);

    }

    .waf-team {
        padding: 0;
        margin-left: 0;
        margin-right: 0;

        .layout-wrapper {
            max-width: calc(var(--container-max-width) - calc((var(--window-inner-width) - var(--container-max-width))/2));
            margin: auto;
            position: relative;
        }

        .card {
            &-list {
                display: grid;
                grid-auto-flow: row;
                grid-template-columns: repeat(12, 1fr);
                grid-gap: var(--full-space);
                overflow: hidden;
                margin-bottom: calc(3 * var(--full-space));
            }

            &-wrapper {
                height: 100%;
            }

            &-head {
                height: 100%;
                padding: 0;
                position: relative;
                z-index: var(--z-index1);
                transition: .7s all;
                @include flex-config(null, column, space-between, null);

            }

            &-image {
                width: 100%;
                height: calc(100% - 11rem);
                border: unset;
                @include border-radius(unset);
                @include flex-config(flex, null, center, center);

                .img-box {
                    width: 16rem;
                    height: 16rem;
                    border: 0.2rem solid var(--accent-color);
                    @include border-radius(50%);
                }

                img {
                    height: 100%;
                    object-fit: contain;
                    padding: var(--full-space);
                }
            }

            &-title {
                height: 11rem;
                width: 100%;
                margin: 0;
                background-color: var(--white-color);
                color: var(--secondary-color);
                @include flex-config(flex, null, center, center);


            }

            &-body {
                text-align: center;
                width: 100%;
                height: 100%;
                @include position(50%, null, null, 50%);
                @include center(both);
                @include transition(all 0.9s ease-out 0s)
            }

            &-item {
                position: relative;
                height: 44rem;
                width: 100%;
                margin: 0 0 var(--full-space);

                &:first-child {
                    margin-left: 0;
                }

                &.pro-kabaddi {
                    grid-area: 1/1/4/5;
                }

                &.ukk {
                    grid-area: 1/9/4/13;
                }

                &.legend-cricket {
                    grid-area: 1/5/4/9;
                }

                &.polo {
                    grid-area: 4/3/6/7;
                }

                &.boxing {
                    grid-area: 4/7/6/11;
                }

                &:hover {
                    .card-head {
                        transform: translateY(-100%);
                    }
                }
            }


        }
    }

    .home-video {
        background: var(--white-color);

        .title {
            font: 2.4rem/3.6rem $font-bold;
        }
    }
}