@use "./config" as *;

.waf-standings {
    // z-index: var(--standing-z);
    margin: 0 var(--half-space-negative);
    .standings-table {
        @include border-radius(0 0 var(--half-radius) var(--half-radius));
    }
    .waf-head {
        margin-bottom: var(--full-space);
        @include flex-config(flex, null, space-between, null);
    }

    .waf-footer {
        display: none;
    }

    .table {
        @include flex-config(flex, null, null, null);
        &-head {
            .table-data {
                p,
                .text {
                    font-family: $font-bold;
                }
            }
        }

        &-body {
            .table-data {
                height: 5.44rem;
            }
        }

        &-left {
            width: 44%;
            flex-shrink: 0;
        }

        &-right {
            width: 56%;
            flex-shrink: 0;
            overflow-x: scroll;
        }

        &-data {
            flex-shrink: 0;
            @include flex-config(flex, null, center, center);

            &.position {
                width: 30%;
            }

            &.team {
                width: 70%;
                @include flex-config(null, null, flex-start, null);
            }

            &.position,
            &.team,
            &.points {
                p,
                .text {
                    font-family: $font-bold;
                }
            }

            p,
            .text {
                font: 1.2rem/1.4 $font-regular;
                text-transform: capitalize;
            }
            &.nr,
            &.net-rr,
            &.score-diff {
                display: none;
            }
        }

        &-row {
            height: 100%;
            width: 100%;
            @include flex-config(flex, null, null, null);
            &.qualify {
                .position {
                    .text {
                        width: 2rem;
                        height: 2rem;
                        background-color: var(--kabaddi-accent-color);
                        color: var(--white-color);
                        @include flex-config(flex, null, center, center);
                    }
                }
            }
            &-wrap {
                width: inherit;
                height: inherit;
            }
        }
    }

    .team {
        &-image {
            width: 3.5rem;
            height: 3.5rem;
            flex-shrink: 0;
            margin-right: var(--half-space);
        }

        &-wrap {
            width: 100%;
            flex-basis: 100%;
            @include flex-config(flex, null, null, center);
        }

        &-information {
            @include flex-config(flex, null, null, center);
        }
    }

    .row-head {
        height: 100%;
        @include flex-config(flex, null, null, null);
    }
}

@media screen and (min-width: $tablet-min-width) {
    .waf-standings {
        .table-data {
            &.nr,
            &.net-rr,
            &.score-diff {
                @include flex-config(flex, null, null, null);
            }
        }
    }
    .wpl-page{
        .waf-standings{
            .team-image{
                width: 4.5rem;
                height: 4.5rem;
              }
        }
    }
}
