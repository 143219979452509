@use "./config" as *;
.waf-showcase {
	padding: 0;
	margin: 0 var(--half-space-negative);
	.preview-swiper {
		position: relative;
		.article {
			&-content {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 0 var(--full-space) var(--full-space);
				@include flex-config(flex, column, center, flex-start);
			}
			&-wrap {
				height: 100%;
			}
			&-item {
				&:hover,
				&:focus {
					.img-box {
						img {
							transform: none;
						}
					}
				}
			}
			&-thumbnail {
				height: 100%;
				clip-path: unset;
			}
			&-list {
				position: relative;
				height: 100%;
				overflow: scroll;
				transition-property: transform;
				@include flex-config(flex, row, null, null);
				.article {
					&-item {
						position: relative;
						flex-shrink: 0;
						width: 100%;
						height: 100%;
						&::before {
							content: "";
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 100%;
							background-size: cover;
							background-position: left bottom;
							z-index: var(--z-index1);
							pointer-events: none;
							@include linear-gradient(360deg, var(--kabaddi-secondary-color) 10%, transparent 20%);
						}
					}
					&-title {
						position: relative;
						font-size: 2.2rem;
						font-family: $font-bold;
						color: var(--white-color);
						margin: 0;
						z-index: var(--z-index1);
						overflow: hidden;
						@include truncate-vertical(3, 6rem);
					}
					&-meta {
						margin: var(--full-space) 0;
						.meta {
							color: rgba(var(--white-color-rgb), 0.5);
							font-size: 1.1rem;
							position: relative;
							z-index: var(--z-index10);
							font-family: $font-bold;
						}
					}
				}
			}
		}
	}
	.swiper-pagination {
		display: none;
	}
	.swiper-button-prev,
	.swiper-button-next {
		height: 2.8rem;
		width: 5.9rem;
		background: transparent;
		border: none;
		cursor: pointer;
		&::after {
			content: "";
			color: var(--kabaddi-accent-color);
			font: 1.3rem/1.2 $font-icon;
			background: url(/static-assets/images/cssimages/shape-icon.png?v=#{$image-version}) no-repeat;
			background-size: 100% 100%;
			width: 100%;
			height: 100%;
			@include transition(500ms ease-in);
		}
		&.swiper-button-disabled {
			opacity: 0.2;
			filter: grayscale(100%);
			pointer-events: none;
		}
	}
	.swiper-button-next {
		@include transform(scaleX(-1));
	}
}
@media screen and (min-width: $tablet-min-width) {
	.waf-showcase {
		.swiper-button {
			&-next,
			&-prev {
				position: absolute;
				bottom: 9rem;
				z-index: var(--z-index1);
			}
			&-next {
				right: 9rem;
				@include transform(rotate(-90deg));
			}
			&-prev {
				right: 14rem;
				@include transform(rotate(90deg));
			}
		}
	}
}
@media screen and (min-width: $desktop-min-width) {
	.waf-showcase {
		.article-thumbnail a {
			height: 100%;
			display: block;
			.img-box {
				height: inherit;
			}
		}
		.preview-swiper {
			height: calc(var(--window-inner-height) - var(--primary-header-height));
			// transition: all 0.5s ease-in-out;
			@include transition(500ms all ease-in-out);
			.article {
				&-content {
					padding-left: calc(9 * var(--full-space));
					width: 75%;
				}
				&-list {
					overflow: unset;
					height: 100%;
					.article-item {
						&::before {
							@include linear-gradient(360deg, var(--kabaddi-secondary-color) 0%, transparent 20%);
						}
					}
					.article-title {
						font-size: 2.8rem;
						height: 7.5rem;
					}
				}
			}
		}
	}
	.scorestrip-toggle {
		.waf-showcase {
			.preview-swiper {
				height: calc(var(--window-inner-height) - var(--primary-header-height) - 9rem);
			}
		}
		&.secondary-menu {
			.waf-showcase {
				.preview-swiper {
					height: calc(var(--window-inner-height) - var(--microsite-primary-header-height) - var(--secondary-header-height) - var(--breadcrumb-height) - 9rem);
				}
			}
		}
	}
	.secondary-menu {
		.waf-showcase {
			.preview-swiper {
				height: calc(var(--window-inner-height) - var(--microsite-primary-header-height) - var(--secondary-header-height) - var(--breadcrumb-height));
			}
		}
	}
}