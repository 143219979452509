@use "../config" as *;
:root {
  --modal-gap: 3rem;
}
.waf-modal {
  position: fixed;
  z-index: var(--zindex-modal);
  top: 0;
  left: 0;
  background-color: rgba($color: var(--black-color-rgb), $alpha: .8);
  @extend %w-100;
  @extend %h-100;
  @extend %d-none;
  &.waf-component {
    @extend %p-zero;
    @extend %m-t-zero;
  }
  &.active {
    @include flex-config(flex, null, center, flex-end);
  }
  .modal {
    &-dialog {
      width: calc(100% - 3rem);
      height: calc(100% - var(--microsite-primary-header-height) - var(--secondary-header-height) - var(--modal-gap));
      position: relative;
      background-color: var(--white-color);
      @extend %m-b-full;
    }
    &-content {
      @extend %h-100;
    }
    &-body {
      padding: 1rem;
      @extend %h-100;
    }
    &-footer {
      @include position-style(null, -1rem, -1rem);
    }
  }
  .btn-close {
    width: 2rem;
    height: 2rem;
    background-color: var(--lost-color);
    @include flex-config(flex, null, center, center);
    @extend %circle-radius;
  }
  embed,
  iframe {
    display: block;
    @extend %w-100;
    @extend %h-100;
  }
  .btn-close {
    .btn-text {
      @extend %font-zero;
      &::before {
        content: "\e81b";
        color: var(--white-color);
        font-family: $font-icon;
        font-size: 1.4rem;
      }
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  :root {
    --modal-gap: 5rem;
  }
  .waf-modal {
    .modal {
      &-dialog {
        width: 80%;
        margin-bottom: 2.5rem;
        background-color: var(--white-color);
      }
      &-body {
        &-body {
          padding: 2rem;
        }
      }
      &-footer {
        top: -1.6rem;
        right: -1.6rem;
      }
    }
    .btn-close {
      width: 3.2rem;
      height: 3.2rem;
      .btn-text {
        &::before {
          font-size: 1.8rem;
        }
      }
    }
  }
}