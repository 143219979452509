@use "./config" as *;

.waf-team.widget-layout-01 {
  padding: 0;
  margin: 0;
  .layout-wrapper {
    max-width: var(--container-max-width);
    margin: 0 auto;
  }
  .tab-section,
  .card-information-b,
  .head-wrap {
    display: none;
  }

  .waf-head {
    margin-top: var(--half-space-negative);
    margin-bottom: 0;

    &:before {
      content: "";
      // url("/static-assets/images/cssimages/sqaud-pattern.jpg?v=#{$image-version}") center center / cover no-repeat
      background: var(--kabaddi-secondary-color);
      width: var(--window-inner-width);
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      @include transform(translateX(-50%));
      // left: calc(((var(--window-inner-width) - var(--container-max-width)) + var(--full-space)) / 2 * -0.5);
    }
  }

  .card-information {
    .card-information-wrap {
      padding: 2.5rem 0;
      position: relative;
      z-index: var(--z-index1);

      .card-thumbnail {
        width: 14rem;
        height: 14rem;
        background-color: var(--white-color);
        flex-shrink: 0;
        margin: auto;
        padding: 2rem;
        @include border-radius(100%);

        img {
          // @include border-radius(100%);
        }
      }

      .card-content {
        color: var(--white-color);
        width: 100%;
        text-align: center;
      }

      .content-title {
        font-size: 2.6rem;
        font-family: $font-bold;
        border-bottom: 0.1rem solid rgba(var(--white-color-rgb), 0.2);
        padding-bottom: var(--full-space);
        margin-bottom: var(--full-space);
        margin-top: var(--full-space);
      }

      .champions,
      .venue,
      .social-share-wrap {
        display: none;
      }

      .social-text {
        font-size: 0;
      }

      .social-quicklinks {
        padding-left: 2rem;
        @include flex-config(flex, null, null, center);
        .social-links {
          margin-right: var(--full-space);
        }
        .social-item {
          color: var(--white-color);
        }
      }

      .website {
        // margin-bottom: var(--full-space);
        @include flex-config(flex, null, center, null);
        display: none;

        // &:last-child {
        //   display: flex;
        // }

        .card-label {
          margin-right: var(--half-space);
          font-size: 1.1rem;

          &::before {
            content: "\e805";
            font-family: $font-icon;
            font-size: 1.4rem;
            display: inline-block;
            margin-right: var(--half-space);
            color: var(--white-color);
          }
        }

        .card-text {
          color: var(--up-accent-color);
          cursor: pointer;
        }
      }
      .share {
        @include flex-config(flex, null, center, center);
      }
      .social-wrap {
        @include flex-config(flex, null, center, null);

        .share-label {
          margin-right: var(--full-space);
        }
      }
    }
  }
}

.waf-squad {
  .waf-head,
  .tab-section {
    display: none;
  }
  .squad {
    &-title {
      font: 1.6rem/1.4 $font-bold;
      color: var(---kabaddi-secondary-color);
      // margin-bottom: 3rem;
    }

    &-group {
      // padding-bottom: calc(2 * var(--full-space));
      // margin-bottom: calc(2 * var(--full-space));
      position: relative;
      border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.3);

      &::after {
        content: "";
        position: absolute;
        height: 0.1rem;
        width: 100%;
        background-color: var(--light-gray-color);
        left: 0;
        bottom: 0;
      }
    }

    &-listing {
      @include flex-config(flex, column, null, null);
    }

    &-item {
      height: 10rem;
      margin: calc(var(--full-space) * 3) 0 calc(var(--full-space) * 2);
      background: url("/static-assets/images/cssimages/player-bg.png?v=#{$image-version}") center center / 100% 100%;
      position: relative;
      transition: 0.2s all;
      &:hover {
        background: url("/static-assets/images/cssimages/home-squad-bg.png?v=#{$image-version}") center center / 100%
          100%;
        .squad-thumbnail {
          img {
            transform: translate(0.2rem, 0.4rem) scale(1.1);
          }
        }
      }
      &.captain {
        .squad-wrap {
          position: relative;

          &::after {
            content: "c";
            position: absolute;
            right: var(--half-space);
            top: var(--half-space);
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            overflow: hidden;
            font-size: 1.2rem;
            font-family: $font-medium;
            background-color: var(--kabaddi-secondary-color);
            color: var(--white-color);
            line-height: 1.4;
            @include flex-config(flex, null, center, center);
            display: none;
          }
        }
      }
    }

    &-wrap {
      height: 100%;
      @include flex-config(flex, null, null, null);
    }

    &-thumbnail {
      height: 16rem;
      width: 15rem;
      @include position(null, 1.8rem, 0, null);
      overflow: hidden;
      img {
        transition: 0.2s all;
        height: 100%;
        object-fit: cover;
      }
    }

    &-name {
      align-self: center;
      width: 100%;
      padding: 0 var(--full-space);
    }

    &-score {
      display: none;
    }

    &-content {
      // position: relative;
      width: calc(100% - 10rem);
      flex-basis: calc(100% - 10rem);
      flex-shrink: 0;
      flex-wrap: wrap;
      @include flex-config(flex, null, null, flex-end);
      padding: 1.8rem 0;

      .name {
        color: var(--kabaddi-secondary-color);
      }

      .first-name {
        font: 1.6rem/1.4 $font-bold;
        color: var(---kabaddi-secondary-color);
      }

      .last-name {
        font: 1.6rem/1.4 $font-medium;
        color: var(---kabaddi-secondary-color);
      }
      .category {
        font-size: 1.1rem;
        padding: 0 1.2rem;
        color: var(---kabaddi-secondary-color);
      }

      .view-more {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        font-size: 0;
      }
    }

    &-number {
      display: none;
    }
  }
  &.home-squad-list {
    .waf-head,
    .tab-section {
      @include flex-config(flex, null, space-between, center);
    }
    .squad-listing {
      overflow: auto;
      padding: var(--full-space) 0;
      @include flex-config(null, row, null, null);
    }
    .squad-item {
      width: 85%;
      flex-basis: 85%;
      flex-shrink: 0;
      margin: calc(var(--full-space) * 3) 15px calc(var(--full-space) * 2) 0;
      &:hover {
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          width: 95%;
          height: 0.3rem;
          background-color: var(--kabaddi-accent-color);
        }
      }
    }
  }
}
.wpl-page{
  .waf-squad{
    .squad-thumbnail{
      height: 14rem;
    }
  }
  .waf-team{
    .logo{
      width: 11rem;
      @extend %flex-column-c-c;
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-team.widget-layout-01 {
    .waf-head {
      // margin-top: var(--half-space);
      // margin-bottom: var(--full-space);

      &:before {
        // url("/static-assets/images/cssimages/sqaud-pattern.jpg?v=#{$image-version}") center center / cover no-repeat
        // background: var(--kabaddi-secondary-color);
        // left: calc((var(--window-inner-width) - var(--container-max-width) + var(--full-space)) / 2 * -1);
      }
    }

    .card-information {
      .card-information-wrap {
        @include flex-config(flex, null, null, null);

        .social-wrap {
          justify-content: flex-start;
        }

        .card-content {
          margin-left: 3rem;
          text-align: left;
        }

        .website {
          @include flex-config(null, null, flex-start, null);
        }
        .share{
          @include flex-config(flex,null,flex-start,flex-start);
        }
      }
    }
  }

  .waf-squad {
    // margin-top: calc(var(--full-space) * 3);
    .layout-wrapper {
      max-width: var(--container-max-width);
      margin: 0 auto;
    }
    .squad {
      &-listing {
        margin: 0 var(--half-space-negative);
        flex-wrap: wrap;
        @include flex-config(null, row, null, null);
      }

      // &-group {
      //   border-bottom: none;
      // }

      &-item {
        position: relative;
        flex-shrink: 0;
        padding-top: var(--half-space);
        @include card-count(4, var(--full-space));
      }
    }

    &.home-squad-list {
      margin-top: 4rem;
      padding: 0;
      .squad-head {
        display: none;
      }
      .waf-head {
        // display: block;
        margin-bottom: var(--full-space);
      }
      .squad-group {
        padding: 0;
        margin: 0;
      }
      // .tab-section {
      //   display: none;
      // }
      .squad-item {
        @include card-count(3, var(--full-space));
      }
    }
  }
  .wpl-page{
    .waf-squad{
      .squad{
        &-item{
          height: 15rem;
          @include card-count(3,var(--full-space));
        }
        &-content{
          .first-name{
            font: 2rem/1.8rem $font-bold;
            margin-bottom: var(--half-space);
          }
          .last-name{
            font: 2rem/1.8rem $font-medium;
          }
        }
        &-thumbnail{
          width: 20rem;
          height: 20rem;
          right: 2rem;
        }
      }
    }
  }
}


@media screen and (min-width: $tablet-min-width) and (max-width: $large-desktop-min-width) {
  .waf-squad {
    .squad-item {
      width: 45%;
      flex-basis: 45%;
      margin-bottom: 1rem;
    }
  }
  .wpl-page{
    .waf-squad {
      .squad-item {
        width: calc(50% - var(--full-space));
        flex-basis: calc(50% - var(--full-space));
        margin-bottom: 1rem;
      }
    }
  }
}
